<template>
  <div class="content-info">
    <h1>{{ content.title }}</h1>
    <img class="logo" :src="content.logo" />
    <p>{{ content.description }}</p>
    <img class="img" v-if="content.img" :src="content.img" />
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
</style>